<template>
  <section class="main-content-section">
    <div class="grid">
      <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
        <div v-if="order.lines.length > 0" class="register-product-summary">
          <h2 class="page-title">{{ selectedProducts }}</h2>
          <div
            v-for="line in order.lines"
            :key="line.id"
            class="flex justify-space-between register-product"
          >
            <figure>
              <img
                :src="line.imageUrl"
                :alt="line[`name${selectedLanguage}`]"
              />
            </figure>
            <div class="flex flex-column justify-end">
              <h4>{{ line[`name${selectedLanguage}`] }}</h4>
              <div class="flex justify-end">
                <span>{{ amount }}</span>
                <span>{{ line.quantity }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
        <div class="form-container register-form">
          <div class="form-header">
            <h1 class="page-title">{{ createYourAccount }}</h1>
            <div class="info">{{ pleaseFillInYourName }}</div>
          </div>

          <form @submit.prevent="submitForm()">
            <div v-if="isFirstNameVisible" class="form-row flex flex-column">
              <label for="firstName"
                >{{ firstNameLabel }}
                <span v-if="isFirstNameRequired">*</span></label
              >
              <template v-if="isFirstNameRequired">
                <input
                  type="text"
                  id="firstName"
                  v-model.trim="firstName"
                  @input="$v.firstName.$touch()"
                  :class="{ error: $v.firstName.$error }"
                  @blur="saveDataToLocalStorage('firstName', firstName)"
                />
                <div v-if="$v.firstName.$dirty">
                  <p class="error" v-if="!$v.firstName.required">
                    {{ firstNameLabel }} {{ isRequired }}
                  </p>
                  <p class="error" v-if="!$v.firstName.valid">
                    {{ invalidSpecialCharacters }}
                  </p>
                </div>
              </template>
              <template v-else>
                <input
                  type="text"
                  id="firstName"
                  v-model.trim="firstName"
                  @blur="saveDataToLocalStorage('firstName', firstName)"
                />
              </template>
            </div>

            <div v-if="isLastNameVisible" class="form-row flex flex-column">
              <label for="lastName"
                >{{ lastNameLabel }}
                <span v-if="isLastNameRequired">*</span></label
              >
              <template v-if="isLastNameRequired">
                <input
                  type="text"
                  id="lastName"
                  v-model.trim="lastName"
                  @input="$v.lastName.$touch()"
                  :class="{ error: $v.lastName.$error }"
                  @blur="saveDataToLocalStorage('lastName', lastName)"
                />
                <div v-if="$v.lastName.$dirty">
                  <p class="error" v-if="!$v.lastName.required">
                    {{ lastNameLabel }} {{ isRequired }}
                  </p>
                  <p class="error" v-if="!$v.lastName.valid">
                    {{ invalidSpecialCharacters }}
                  </p>
                </div>
              </template>
              <template v-else>
                <input
                  type="text"
                  id="lastName"
                  v-model.trim="lastName"
                  @blur="saveDataToLocalStorage('lastName', lastName)"
                />
              </template>
            </div>

            <div v-if="isLoyaltyCardVisible" class="form-row flex flex-column">
              <label for="lastName"
                >{{ loyaltyCardNumber }}
                <span v-if="isLoyaltyCardRequired">*</span></label
              >
              <template v-if="isLoyaltyCardRequired">
                <input
                  type="text"
                  id="cardNumber"
                  v-model.trim="cardNumber"
                  @input="$v.cardNumber.$touch()"
                  :class="{ error: $v.cardNumber.$error }"
                  @blur="saveDataToLocalStorage('cardNumber', cardNumber)"
                />
                <div v-if="$v.cardNumber.$dirty">
                  <p class="error" v-if="!$v.cardNumber.required">
                    {{ loyaltyCardNumber }} {{ isRequired }}.
                  </p>
                </div>
              </template>
              <template v-else>
                <input
                  type="text"
                  id="cardNumber"
                  v-model.trim="cardNumber"
                  @blur="saveDataToLocalStorage('cardNumber', cardNumber)"
                />
              </template>
            </div>

            <div v-if="isDigitalCollector" class="form-row">
              <input
                type="checkbox"
                id="collector"
                v-model="collector"
                @change="handleCheckboxEvent('collector', collector)"
              />
              <label for="collector"
                ><div class="custom-checkbox"></div>
                {{ digitalCollector }}</label
              >
            </div>

            <div class="form-row">
              <span class="small">* {{ indicatesRequiredField }}</span>
            </div>

            <div class="form-row flex justify-space-between">
              <router-link
                :to="`/login?orderId=${$route.query.orderId}`"
                class="btn btn-secondary"
                >{{ alreadyHaveAnAccount }}</router-link
              >
              <button
                type="submit"
                class="btn btn-primary alternative submitBtn"
              >
                {{ nextStep }} (1/3)
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <WarningMessageModal
      v-show="showWarningModal"
      :title="errorTitle"
      :text="errorMessage"
      @closeModal="closeModal()"
    />
  </section>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import WarningMessageModal from "@/components/modals/WarningMessageModal";
import { mapGetters } from "vuex";
import agent from "../../api/agent";

export default {
  name: "RegisterStepOne",
  components: { WarningMessageModal },
  data() {
    return {
      firstName: "",
      lastName: "",
      cardNumber: "",
      collector: false,
      errorTitle: "",
      errorMessage: "",
      showWarningModal: false,
    };
  },

  created() {
    this.setDocumentTitle();

    if (!this.order && this.$route.query.orderId) {
      this.$store.dispatch("fetchOrder", this.$route.query.orderId);
    }

    if (this.$route.query.productId && this.$route.query.quantity) {
      this.addDirectOrder();
    }

    this.firstName =
      this.$store.getters.getFirstname || localStorage.getItem("firstName");
    this.lastName =
      this.$store.getters.getLastname || localStorage.getItem("lastName");
    this.cardNumber =
      this.$store.getters.getLoyaltyCardNumber ||
      localStorage.getItem("cardNumber");
    this.collector =
      this.$store.getters.getDigitalCollector ||
      !!localStorage.getItem("collector");
  },

  updated() {
    this.setDocumentTitle();
  },

  validations: {
    firstName: {
      required,
      valid: (value) => {
        const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}/]/.test(value);
        return !containsSpecial;
      },
    },
    lastName: {
      required,
      valid: (value) => {
        const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
        return !containsSpecial;
      },
    },
    cardNumber: { required },
    collector: { required, sameAsChecked: sameAs(() => true) },
  },

  computed: {
    ...mapGetters([
      "metaTitle",
      "selectedLanguage",
      // Settings
      "isFirstNameVisible",
      "isFirstNameRequired",
      "isLastNameVisible",
      "isLastNameRequired",
      "isLoyaltyCardVisible",
      "isLoyaltyCardRequired",
      "isDigitalCollector",
      // Texts
      "isRequired",
      "indicatesRequiredField",
      "digitalCollector",
      "createYourAccount",
      "pleaseFillInYourName",
      "loyaltyCardNumber",
      "alreadyHaveAnAccount",
      "nextStep",
      "notAllRequiredFieldsAreCompleted",
      "amount",
      "selectedProducts",
      "invalidSpecialCharacters",
    ]),
    ...mapGetters({
      order: "getOrder",
      orderId: "getOrderId",
      // Texts
      firstNameLabel: "firstName",
      lastNameLabel: "lastName",
    }),
  },

  methods: {
    setDocumentTitle() {
      document.title = this.createYourAccount + this.metaTitle;
    },

    showModal() {
      this.showWarningModal = true;
    },

    closeModal() {
      this.showWarningModal = false;
    },

    saveDataToStore() {
      this.$store.dispatch("setFirstname", this.firstName);
      this.$store.dispatch("setLastname", this.lastName);
      this.$store.dispatch("setLoyaltyCardNumber", this.cardNumber);
      this.$store.dispatch("setDigitalCollector", this.collector);
    },

    saveDataToLocalStorage(key, data) {
      localStorage.setItem(key, data);
    },

    handleCheckboxEvent(key, data) {
      this.$v[key].$touch();
      this.saveDataToLocalStorage(key, data);
    },

    async addDirectOrder() {
      const directOrder = {
        productId: this.$route.query.productId,
        quantity: this.$route.query.quantity,
        storeId: this.$route.query.storeId,
        couponId: this.$route.query.couponcode,
      };

      try {
        const orderId = await agent.order.addDirectOrder(directOrder);
        this.$store.dispatch("setOrderId", orderId);
      } catch (error) {
        console.error(error);
      }
    },

    submitForm() {
      // Warning modal message handlers and prevent navigation to next step
      if (
        this.isFirstNameVisible &&
        this.isFirstNameRequired &&
        this.$v.firstName.$invalid
      ) {
        this.errorTitle = this.notAllRequiredFieldsAreCompleted;
        this.errorMessage = this.firstNameLabel + " " + this.isRequired;
        this.showModal();
        return;
      }

      if (
        this.isLastNameVisible &&
        this.isLastNameRequired &&
        this.$v.lastName.$invalid
      ) {
        this.errorTitle = this.notAllRequiredFieldsAreCompleted;
        this.errorMessage = this.lastNameLabel + " " + this.isRequired;
        this.showModal();
        return;
      }

      if (
        this.isLoyaltyCardVisible &&
        this.isLoyaltyCardRequired &&
        this.$v.cardNumber.$invalid
      ) {
        this.errorTitle = this.notAllRequiredFieldsAreCompleted;
        this.errorMessage = this.loyaltyCardNumber + " " + this.isRequired;
        this.showModal();
        return;
      }

      if (this.isDigitalCollector && this.$v.collector.$invalid) {
        this.errorTitle = this.notAllRequiredFieldsAreCompleted;
        this.errorMessage = this.digitalCollector + " " + this.isRequired;
        this.showModal();
        return;
      }

      // Store data in store if conditions are met
      this.saveDataToStore();

      if (this.orderId) {
        // Go to next step by AddDirectOrder
        this.$router.push(`/register/step-2?orderId=${this.orderId}`);
      } else {
        // Go to next step by catalog created orderId in query string
        this.$router.push(
          `/register/step-2?orderId=${this.$route.query.orderId}`
        );
      }
    },
  },
};
</script>
